import styles from './about.module.css';

export default function About() {
    return (
        <section className={styles.about}>
            <p className={styles.paragraph}>
            1908 год — это год падения Тунгусского метеорита, который стал одной из главных загадок современной истории. 
            Многие учёные пытались разгадать его загадку, но в итоге мы имеем лишь сотню разнообразных теорий.
            </p>
            <p className={styles.paragraph}>
            В 2020 году на другом конце страны открылось не менее таинственное место — секретный бар на улице Рубинштейна. 
            Даже если вы случайно окажетесь у нужной двери, вы не поймёте, что за ней кроется.
            </p>
        </section>
    )
}